body.sign {
    background-color: #070707;
    /* font-family: Inter, sans-serif; */
    color: #a7b5c4;
    font-size: 16px;
    line-height: 1.333;
    font-weight: 400;
}
body.sign header{
    position: sticky;
    z-index: 9;
    top: 0;
	
}
body.sign .container-xl {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 64em;
    margin-right: auto;
    margin-left: auto;
    padding: 1.25em;
}
body.sign header .container-xl {
	   z-index: 9999;
}
body.sign .logo img{
	width:70px;
}
body.sign .button-small {
    border-style: solid;
    border-width: 1px;
    border-color: #5c5c5c;
    background-color: transparent;
    color: #bcc9d8;
    font-size: 0.75em;
    font-weight: 600;
    letter-spacing: 0px;
    /* padding: 0.75em 1.25em!important; */
    border-radius: 20px;
    text-decoration: none;
}
body.sign .button-small-bg {
    border:none;
    border-radius: 9999px;
    background: #f2d131;
    background-image: linear-gradient(90deg,#7e4601,#c07604 35%,#f3cf1f);
	color:#000;
    letter-spacing: 0px;
    /* padding: 0.75em 1.25em!important; */
    text-decoration: none;
}

body.sign .button-small-bg:hover,body.sign .button-small:hover{
	color:#fff
}
body.sign .bg-pattern-wrap {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
body.sign .bg-pattern-image {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: auto;
    width: 100%;
    margin-top: auto;
    margin-bottom: 72px;
    background-color: rgba(7, 7, 7, 0.88);
    -o-object-fit: contain;
    object-fit: contain;

}
body.sign .outer-container {
    position: relative;
}
body.sign .bg-pattern-wrap.centered{
    max-width: 1050px;
    left: 50%;
    transform: translateX(-50%);	
}
body.sign .title-lg {
    position: relative;
    z-index: 5;
    /* font-family: 'Inter ', sans-serif; */
    color: #ccdae7;
    font-size: 4em;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: -0.02em;
}
body.sign .text-span-4 {
    background-image: -webkit-gradient(linear, left top, right top, from(#079de0), to(#3aecba));
    background-image: linear-gradient(90deg, #079de0, #3aecba);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
body.sign .title-xs {
    position: relative;
    z-index: 5;
    /* font-family: 'Inter', sans-serif; */
    color: #ccdae7;
    font-size: 2.5em;
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: -0.025em;
}
body.sign .text-color-gray-500 {
    color: #636c7c;
}
body.sign .login-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 0.75em 1.25em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 42px;
    grid-row-gap: 42px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: inset 0 0 100px 100px transparent;
    -webkit-transition: background-color 300ms ease, opacity 300ms ease, box-shadow 300ms ease, color 300ms ease;
    transition: background-color 300ms ease, opacity 300ms ease, box-shadow 300ms ease, color 300ms ease;
    color: #27272a;
    font-size: 1em;
    line-height: 1;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.01em;
	text-decoration:none;
	position:relative;
}
body.sign .login-buttons {
    max-width: 384px;
    margin: 0px auto;
    grid-column-gap: 1px;
    grid-row-gap: 30px;
    grid-auto-columns: 1fr;
    display: grid;
    margin-top: 10px;
}
.login-buttons iframe{
    width: 100% !important;
    margin: 0 -4px !important;
}
/* body.sign .login-btn:hover {
    box-shadow: inset 0 0 200px 200px rgba(7, 7, 7, 7.15);
	color:#fff;
    background-color: #f6f0fc;
    color: #27272a;
} */
body.sign .login-btn img {
    width: 27px;
    position: absolute;
    left: 26px;
}
body.sign .overflow-hidden {
    position: relative;
    overflow: hidden;
}
body.sign .overflow-hidden .text-muted {
    color: #fff !important;
}
body.sign .section {
    position: relative;
    width: 100%;
    padding-top: 0em;
    padding-bottom: 5em;
}
body.sign .bg-neutral-600 {
  background-color: #272e35;
}
body.sign .bg-tile-top {
    position: relative;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#070707), color-stop(15%, rgba(7, 7, 7, 0))), -webkit-gradient(linear, left top, left bottom, from(#070707), color-stop(15%, rgba(7, 7, 7, 0))), radial-gradient(circle farthest-side at 50% 0%, rgba(7, 7, 7, 0), #070707), url(../images/bg-tile-dark.svg);
    background-image: linear-gradient(0deg, #070707, rgba(7, 7, 7, 0) 15%), linear-gradient(180deg, #070707, rgba(7, 7, 7, 0) 15%), radial-gradient(circle farthest-side at 50% 0%, rgba(7, 7, 7, 0), #070707);
    background-position: 0px 0px, 0px 0px, 0px 0px, -1px -1px;
    background-size: auto, auto, auto, 32px;
}

body.sign .section.bg-tile-top.bg-neutral-600 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 1em;
    padding-bottom: 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
/* body.sign .navbar-shadow {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 0;
    width: 100%;
    height: 100%;
	    will-change: opacity;
    opacity: 1;
    background-color: rgba(7, 7, 7, 0.88);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
} */
body.sign .form-input{
	    max-width: 384px;
    margin: 0px auto;
    grid-column-gap: 1px;
    grid-row-gap: 10px;
    grid-auto-columns: 1fr;
    display: grid;
}
body.sign .form-input input {
    border: 1px solid #ccc;
    background: transparent;
    color: #fff;
    padding: 8px 8px;
    border-radius: 5px;
    margin-top: 10px;
}
@media only screen and (max-width:767px){
	
	body.sign .sign-buttons {
    text-align: center;
    margin-top: 20px;
	display:none;
}
body.sign .title-lg{
	font-size: 2em;
}
body.sign .title-xs{
font-size: 1.5em;	
}

.menu {
    margin-top: 20px;
}
a.button-small-bg {
    padding: 7px 8px;
}
}

