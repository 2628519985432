.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button[type="submit"]:hover, button:disabled{
  background-color: #9fa3a9;
  color: #fff;
}

pre{
  position: relative;
}

a, a:hover{
  text-decoration: none !important;
}
a:hover, a:focus, .nav-link:hover{
  color: #f2d131 !important;
}
a.button-small-bg:hover, a.btn-style:hover{
  color: #000 !important;
  background-color: #fff !important;
}
ul.navbar-nav{
  font-size: 18px;
}
header {
  position: sticky;
  top: 0;
  background: rgba(28, 28, 28, 0.7);
  z-index: 99;
}

img.profile-icon{
  border-radius: 9999px;
  max-width: inherit;
}

.Toastify svg {
  fill:#f2d131 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
