/* .sidebar-code {
    width: 250px;
    height: 100%;
    padding: 0;
    position: relative;
    left: 0;
    transition: all 0.5s ease;
    display: inline-block;
    vertical-align: top;
}

.sidebar-code-right {
    width: 250px;
    height: 100%;
    padding-left: 15px;
    position: relative;
    left: 0;
    transition: all 0.5s ease;
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid #5c5c5c;
    z-index: 9999;
} */

.sidebar-code, .sidebar-code-right {
    display: inline-block;
    height: 100%;
    left: 0;
    position: sticky;
    transition: all .5s ease;
    vertical-align: top;
    width: 250px;
    top: 0;
}

.sidebar-code.open {
    left: 0;
}

.sidebar-code-right ul {
    padding: 0;
}

.sidebar-code-right ul li {
    display: inline-block;
    width: 100%;
    border-radius: 0.5rem;
}

.sidebar-code-right ul:not(.histories) li a {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
    color: #fff;
    text-decoration: none;
    padding: 10px 13px;
    font-size: 14px;
    text-align: left;
    background: #27272a;
}

.sidebar-code-right ul li a.new-chat svg {
    margin-right: 9px;
}

.sidebar-code-right .separator{
    align-items: center;
}

.sidebar-code-right .line{
    height: 2px;
    flex: 1;
    background-color: #fff;    
}

ul.histories li span.delete-history{
    float: right;
    display: none;
    cursor: pointer;
}

ul.histories li:hover span.delete-history{
    display: flex;
}

.react-responsive-modal-root{
    z-index: 9999 !important;
}
.customOverlay {
    background: rgba(3, 7, 18, 0.8) !important;
}
.customModal {
    background: rgb(3, 7, 18) !important;
    width: 100%;
    border-radius: 0.5rem;
    border-width: 1px;
    border-block-end-color: rgb(31, 41, 55);
border-block-end-style: solid;
border-block-end-width: 0.833333px;
border-block-start-color: rgb(31, 41, 55);
border-block-start-style: solid;
border-block-start-width: 0.833333px;
border-bottom-color: rgb(31, 41, 55);
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
border-bottom-style: solid;
border-bottom-width: 0.833333px;
border-block-end-color:rgb(39, 39, 42);
border-block-end-style: solid;
border-block-end-width: 0.666667px;
border-block-start-color: rgb(39, 39, 42);
border-block-start-style: solid;
border-block-start-width: 0.666667px;
border-bottom-color: rgb(39, 39, 42);
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
border-bottom-style: solid;
border-bottom-width: 0.666667px;
border-collapse: separate;
border-end-end-radius: 8px;
border-end-start-radius: 8px;
border-image-outset: 0;
border-image-repeat: stretch;
border-image-slice: 100%;
border-image-source: none;
border-image-width: 1;
border-inline-end-color: rgb(39, 39, 42);
border-inline-end-style: solid;
border-inline-end-width: 0.666667px;
border-inline-start-color: rgb(39, 39, 42);
border-inline-start-style: solid;
border-inline-start-width: 0.666667px;
border-left-color: rgb(39, 39, 42);
border-left-style: solid;
border-left-width: 0.666667px;
border-right-color: rgb(39, 39, 42);
border-right-style: solid;
border-right-width: 0.666667px;
border-start-end-radius: 8px;
border-start-start-radius: 8px;
border-top-color: rgb(39, 39, 42);
border-top-left-radius: 8px;
border-top-right-radius: 8px;
border-top-style: solid;
border-top-width: 0.666667px;
}

.react-responsive-modal-modal.customModal.modal-sm{
    max-width: 500px;
}