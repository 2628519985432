body {
    background: rgb(7, 7, 7);
    background: radial-gradient(circle, rgba(7, 7, 7, 1) 0%, rgba(29, 29, 29, 1) 100%);
    font-family: 'Poppins', sans-serif;
    ;
    color: #fff;
    font-size: 14px;
    line-height: 1.333;
    font-weight: 400;
}

body a,
body a.nav-link {
    color: #fff;
}

.logo-text {
    color: #f2d131;
}

body.Landing .container-xl {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 64em;
    margin-right: auto;
    margin-left: auto;
    padding: 1.25em;
}

body.Landing header .container-xl {
    z-index: 9999;
}

a.button-small {
    padding: 0;
    text-decoration: none;
}

/* .right-menu a {
    font-size: 18px;
} */

a.button-small-bg {
    padding: 7px 20px;
    border-radius: 21px;
    border: none;
    text-decoration: none;
    background: #f2d131;
    background-image: linear-gradient(90deg, #7e4601, #c07604 35%, #f3cf1f);
    color: #000;
    /* font-size: 18px; */
}

.right-menu {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-left: 10px;
}

body.Landing path.arrow-l {
    stroke-dasharray: 10;
    stroke-dashoffset: 10;
    transition: stroke-dashoffset 200ms;
    transition: all 0.5s ease;
}

body.Landing a:hover path.arrow-l,
body.Landing button:hover path.arrow-l {
    stroke-dashoffset: 20;
}

.footer-text {
    padding-right: 15px;
}

body.Landing .search-box input {
    background: transparent;
    border: none;
    padding: 4px;
    font-size: 13px;
}

body.Landing .search-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #282828;
    border: 1px solid #787878;
    border-radius: 5px;
    padding: 0 4px;
    margin-right: 10px;
}

body.Landing .search-box svg {
    fill: #787878;
}

body.Landing .sub-menu {
    text-align: left;
    padding: 13px 0;
}

body.Landing .sub-menu ul {
    padding: 0;
}

body.Landing .sub-menu li {
    display: inline-block;
    margin-right: 17px;
    font-size: 16px;
}

body.Landing .sub-menu li:first-child a {
    font-size: 22px;
}

body.Landing .sub-menu li a {
    text-decoration: none;
}

body.Landing .text-with-radius {
    border-bottom: 2px solid #d7ba2e;
    border-radius: 0;
    display: inline-block;
    padding: 5px 0px;
}

body.Landing h1 {
    font-size: 45px;
    max-width: 730px;
    margin: 30px auto;
    font-weight: bold;
}

body.Landing a.btn-style {
    text-decoration: none;
    /* background: #fff; */
    color: #000;
    padding: 12px 15px;
    display: inline-block;
    border-radius: 30px;
    font-size: 16px;
    background: #f2d131;
    background-image: linear-gradient(90deg, #7e4601, #c07604 35%, #f3cf1f);
}

body.Landing a.btn-style:hover {
    background: #fff;
    color: #000;
}

a.btn-style:hover,
body a.btn-style:hover {
    background: #fff;
    color: #000 !important;
}

.button-bottom button:hover {
    background-image: linear-gradient(90deg, #7e4601, #c07604 35%, #f3cf1f);
    color: #000;
}

.button-bottom button.filled-btn:hover {
    background: transparent;
    color: #fff;
}

a.button-small-bg:hover {
    color: #f2d131;
    background: #fff;
}

.main-banner {
    padding: 50px 0;
    text-align: center;
}

body.Landing .lp-Hero-videoContainer video {
    width: 100%;
    border-radius: 20px;
    height: auto;
    margin-top: 39px;
}

body.Landing .lp-Hero-videoContainer {
    max-width: 1200px;
    border-radius: 20px;
    overflow: hidden;
}

body.Landing .logos-marquee {
    display: flex;
    overflow: hidden;
    -moz-animation: scroll-left 2s linear infinite;
    -webkit-animation: scroll-left 2s linear infinite;
    animation: scroll-left 20s linear infinite;
}


body.Landing .logos-marquee img {
    margin: 0 40px;


}

body.Landing .content-head {
    text-align: left;
    margin-bottom: 40px;
    margin-top: 50px;
}

.small-heading {
    border: 1px solid #787878;
    border-radius: 19px;
    padding: 5px 20px;
    display: inline-block;
}

.medium-heading {
    font-size: 42px;
    max-width: 850px;
    margin: 20px 0 20px;
    font-weight: bold;
}

body.Landing .content-section {
    padding: 50px 0;
    background: #161b22;
    margin-top: 50px;
}

body.Landing .main-banner h1+p {
    font-size: 17px;
    margin-bottom: 31px;
}

body.Landing .box-1 {
    text-align: left;
    display: flex;
    min-height: 500px;
    flex-wrap: wrap;
    background: #1c1c1c;
    padding: 50px;
    border-radius: 40px;
    align-content: space-between;
    margin: 15px 0;
}

body.Landing .box-1 h3 {
    font-size: 40px;
    text-align: left;
    font-weight: bold;
    width: 100%;
}

body.Landing .box-1 a:after {
    width: 0;
    height: 2px;
    position: absolute;
    background: #fff;
    content: "";
    left: 0;
    bottom: -4px;
    transition: all 0.5s ease;
}

body.Landing .box-1 a:hover {
    color: #fff;
}

body.Landing .box-1 a:hover:after {
    width: 100%;
}

body.Landing .box-1 a {
    font-size: 16px;
    text-decoration: none;
    position: relative;
}

body.Landing .box-img-1 a:after {
    width: 0;
    height: 2px;
    position: absolute;
    background: #fff;
    content: "";
    left: 0;
    bottom: -4px;
    transition: all 0.5s ease;
}

body.Landing .box-img-1 a:hover {
    color: #fff;
}

body.Landing .box-img-1 a:hover:after {
    width: 100%;
}

body.Landing .box-img-1 a {
    font-size: 16px;
    text-decoration: none;
    position: relative;
}

body.Landing .box-2 {
    text-align: left;
    display: flex;
    min-height: 500px;
    flex-wrap: wrap;
    background: #1c1c1c;
    padding: 50px;
    border-radius: 40px;
    align-content: space-between;
    margin: 15px 0;
}

body.Landing .app-img a:after {
    display: none;
}

body.Landing .box-2 h3 {
    font-size: 70px;
    font-weight: bold;
}

body.Landing .box-2 h4,
body.Landing .box-img-1 h3 {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
}

body.Landing .box-2 p {
    font-size: 20px;
}

body.Landing .box-2 img {
    width: 100%;
}

body.Landing .box-img-1 {
    text-align: left;
    display: flex;
    min-height: 100%;
    flex-wrap: wrap;
    align-content: space-between;
}

body.Landing .content-with-image {
    text-align: left;
    min-height: 446px;
    background: #1c1c1c;
    padding: 50px;
    border-radius: 40px;

}

body.Landing .box-img img {
    width: 100%;
    object-fit: cover;
    border-radius: 40px;
}

body.Landing .content-section-2 h3 {
    font-size: 30px;
    font-weight: bold;
}

body.Landing .box-content h3 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
}

body.Landing .box-content {
    border: 1px solid #5a5a5a;
    padding: 45px;
    border-radius: 20px;
    background: #1c1c1c;
    margin-top: 30px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

body.Landing .content-section-2 {
    text-align: left;
    background: url(../images/bg3.jpeg);
    padding: 100px 0;
    background-size: cover;
    background-color: #13171d;
}

body.Landing .content-section-2+.content-section {
    margin-top: 0px;
}

body.Landing .price-value {
    font-size: 80px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    max-width: calc(100% - 60px);
    margin-bottom: 40px;
}

body.Landing .price-row {
    border: 1px solid #5a5a5a;
    padding: 50px 50px 0;
    border-radius: 20px;
    background: #1c1c1c;
    margin-top: 30px;
    margin-bottom: 30px;
}

body.Landing .price-value {
    font-size: 80px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

body.Landing .price-value span {
    font-size: 50px;
    font-weight: normal;
    vertical-align: unset;
    margin-left: 0px;
    margin-top: -17px;
}

body.Landing .price-list-content p {
    font-size: 14px;
    color: #ddd;
}

body.Landing .price-value p {
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
}

body.Landing .price-list-content h3 {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

body.Landing .price-list-content-br {
    border-right: 1px solid #ddd;
}

body.Landing .price-list-content ul li {
    list-style: none;
    width: 100%;
    margin-bottom: 15px;
}

body.Landing .price-list-content ul {
    padding: 0;
}

body.Landing .button-bottom {
    margin: 50px 0;
}

body.Landing .button-bottom button.filled-btn {
    background: #f2d131;
    color: #000;
    margin-right: 10px;
    background-image: linear-gradient(90deg, #7e4601, #c07604 35%, #f3cf1f);
}

body.Landing .button-bottom button {
    background: #0000;
    border: 1px solid #ddd;
    border-radius: 30px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 17px;
    width: 180px;
}

body.Landing .price-row.free-price .button-bottom button.filled-btn svg {
    vertical-align: text-bottom;
    margin-left: 10px;
}

body.Landing .price-row.free-price .price-value {
    border: none;
    padding: 0;
    margin-bottom: 45px;
}

body.Landing .price-row.free-price .button-bottom button.filled-btn {
    width: 100%;
}

body.Landing .content-section-2.content-section-bottom .box-content h3 {
    font-size: 27px;
    margin-bottom: 20px;
}

body.Landing .content-section-2.content-section-bottom .box-content {
    padding: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #21262d;
	color: #f1d030;
}

.nav-pills .nav-link {
    background: none;
		border: 0;
		border-radius: 6px;
		color: #fff;
		font-size: 18px;
		padding: 10px 20px;
		text-align: left;
}

body.Landing .accordion-item {
    background-color: transparent;
    border: none;
}

body.Landing .accordion-body {
    padding: 20px 0;
}

body.Landing .accordion-button {
    background-color: initial!important;
		box-shadow: inset 0 -1px 0 rgba(0,0,0,.125)!important;
		color: #fff !important;
		padding-left: 0!important;
}

body.Landing .tab-pane h3 {
    color: #ddd;
}

body.Landing .accordion-button::after {
    background-image: none;
    transform: none;
    content: "+";
    font-weight: normal;
    font-size: 35px;
    margin-top: 0;
    top: -15px;
    position: relative;
    color: #6e7681;
}

body.Landing .accordion-button:not(.collapsed)::after {
    background-image: none;
		color: #fff;
		font-size: 35px;
		font-weight: 400;
		margin-top: 0;
		position: relative;
		top: -15px;
		transform: none;
}

body.Landing .tab-content {
    margin-top: 30px;
    margin-bottom: 30px;
}

body.Landing .accordion-item {
    border-bottom: 1px solid #ddd;
    border-radius: 0 !important;
}

body.Landing .tab-section ol {
    padding: 0;
    max-width: 1100px;
    margin: 60px auto;
}

body.Landing .tab-section ol li {
    margin-bottom: 13px;
}

.footer-text a {
    border: 1px solid #fff;
    padding: 10px 15px;
    border-radius: 5px;
    margin-top: 0;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #6e7681;
}

footer,
footer a {
    color: #fff;
}

.footer-text h6 {
    margin-top: 20px;
}

.menu ul li a {
    text-decoration: none;
}

.menu ul li {
    list-style: none;
    margin-bottom: 11px;
}

.menu ul {
    padding: 0;
}

.bottom-footer ul li {
    list-style: none;
    display: inline-block;
    margin-right: 10px;
}

.bottom-footer ul {
    padding: 0;
    margin: 0;
}

.bottom-footer ul li img {
    width: 18px;
}

footer {
    padding-top: 50px;
    background: #100f0f;
}

.bottom-footer {
    padding: 12px 0;
    background: #070707;
    margin-top: 40px;
}

body.Landing .bottom-icon {
    text-align: right;
}

img {
    max-width: 100%;
}

body.Landing .features-video video,
.features-video img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    margin-bottom: 40px;
}

body.Landing .feature-box-text span.small-heading {
    margin-bottom: 20px;
}

body.Landing .feature-content-1 h3 {
    font-weight: bold;
}

body.Landing .feature-content-2 p:first-child:after {
    content: "";
    position: absolute;
    left: 3px;
    height: 100%;
    width: 1px;
    background: #fff;
    top: 16px;
}

body.Landing .feature-content-2 p {
    position: relative;
    padding-left: 26px;
    padding-bottom: 30px;
}

body.Landing .feature-content-1 h3 span,
body.Landing .feature-content-2 p span,
body.Landing .feature-box-text p {
    color: #fff;
}

body.Landing .feature-box-text h3 {
    font-weight: bold;
}

body.Landing .row.with-bg {
    background-image: url(../images/features-bento-5-c4770e83b74f.webp);
    background-position: center;
    background-size: cover;
    border-radius: 20px;
}

body.Landing .row.with-bg .box-1 {
    background: transparent;
}

body.Landing .bg1 {
    background: url(../images/features-bento-1.webp);
    background-size: cover;
}

body.Landing .bg2 {
    background: url(../images/bg1.jpeg);
    background-size: cover;
}

body.Landing .bg3 {
    background: url(../images/enterprise-2.webp);
    background-size: cover;
}

body.Landing .bg4 {
    background: url(../images/enterprise-3.jpg);
    background-size: cover;
}

body.Landing .bg5 {
    background: url(../images/bg2.jpeg);
    background-size: cover;
}

body.Landing .box-2.bg3 p {
    width: 100%;
}

body.Landing .bg1.box-1 {
    align-content: flex-start;
}

body.Landing .box-1.app-img {
    margin: 0;
}

body.Landing .feature-content-2 p:before {
    content: "";
    width: 8px;
    height: 8px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 4px;
}

body.Landing .box-bg img {
    border-radius: 30px;
}

body.Landing .feature-box-img video,
.feature-box-img img {
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
}

body.Landing .bottom-icons a img {
    height: 83px;
}

body.Landing .bottom-icons a {
    background: #2a2a2a;
    display: inline-block;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #4a4a4a;
    text-decoration: none;
    text-align: center;
    margin-bottom: 20px;
}

.box-content-img-1 h3 {
    font-size: 40px;
}

.box-content-img-1 p {
    font-size: 30px;
    font-weight: 600;
}


.price-value {
    font-size: 80px;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    max-width: calc(100% - 60px);
    margin-bottom: 40px;
}
.price-list-content{
	
}
.price-row {
    border: 1px solid #5a5a5a;
    padding: 50px 50px 0;
    border-radius: 20px;
    background: #1c1c1c;
    margin-top: 30px;
    margin-bottom: 30px;
}
.price-value {
    font-size: 80px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #f2d131;
}
.price-value span {
    font-size: 50px;
    font-weight: normal;
    vertical-align: unset;
    margin-left: 0px;
    margin-top: -17px;
}
.price-list-content p {
    font-size: 14px;
    color: #f2d131;
}
.price-value  p {
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
}
.price-list-content h3 {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}
.price-list-content-br {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 20px 15px;
	height:100%;
	position:relative;
}
.span-bg {
    position: absolute;
    left: 50%;
    top: -13px;
    background: linear-gradient(90deg, #7e4601 0%, #eecb2d 35%, #f2d131 100%);
    color: #000;
    padding: 3px 10px;
    border-radius: 19px;
    transform: translateX(-50%);
}
.price-list-content ul li {
    list-style: disc;
    width: 100%;
    margin-bottom: 15px;
    position: relative;

}
.price-list-content ul {
    padding: 0;
    padding-left: 20px;
}
section.price-sec {
    margin-bottom: 60px;
}
@media only screen and (min-width:1025px){

.move-up {
    height: 112%;
    margin-top: -6%;
}
}
@media only screen and (max-width:767px){
    .price-value {
        font-size: 40px;
    }
    nav.navbar.navbar-expand-md.row.align-items-center {
        order: 2;
        margin-left: 3px;
    }
    .logo-text {
        font-size: 18px;
    }
    .navbar-brand img{
        width:50px;
    }
}

@media only screen and (max-width:767px) {
    body.Landing h1 {
        font-size: 28px;
    }

    .medium-heading,
    body.Landing .box-1 h3,
    body.Landing .content-section-2 h3 {
        font-size: 27px;

    }

    body.Landing .box-1,
    body.Landing .box-2 {

        min-height: 284px;

        padding: 20px;

    }

    body.Landing .box-2 h3 {
        font-size: 30px;
        font-weight: bold;
    }

    body.Landing .box-2 h4,
    body.Landing .box-img-1 h3 {
        font-size: 27px;
        text-align: left;
        font-weight: bold;
    }

    body.Landing .content-with-image {
        padding: 20px;
    }

    body.Landing .price-row {
        padding: 40px 20px 0;

    }

    body.Landing .price-list-content-br {
        border-right: none;
    }

    body.Landing .button-bottom button {
        width: 47%;
    }

    body.Landing .content-section-2 {
        padding: 30px 0;
    }

    body.Landing .content-section-2.content-section-bottom .box-content {
        padding: 20px;
        height: auto;
    }

    body.Landing .box-content {
        padding: 20px;
        border-radius: 11px;

        height: auto;

    }

    body.Landing .content-head {
        text-align: left;
        margin-bottom: 40px;
        margin-top: 0;
    }

    .menu {
        margin-top: 20px;
    }

    a.button-small-bg {
        padding: 7px 8px;
    }
}

@media only screen and (min-width: 768px) {
    body.Landing svg.navbar-close {
        display: none;
    }
}


.display-flex {
    display: flex;
    align-items: center;
    justify-content: end;
}

div#v-pills-tab {
    flex-wrap: nowrap;
    flex-direction: row !important;
    background: #070707;
    padding: 10px 8px;
    border-radius: 9px;
}

@media only screen and (max-width:1024px) {
    .medium-heading {
        font-size: 42px;
    }

    body.Landing .content-section-2 h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    body.Landing .box-content {
        padding: 30px;

    }
}

@media only screen and (max-width:991px) {
    .medium-heading {
        font-size: 32px;
    }

    body.Landing .box-1,
    .box-2,
    body.Landing .content-with-image {
        padding: 40px;
    }

    body.Landing .box-1 h3,
    body.Landing .box-2 h3,
    body.Landing .box-2 h4,
    body.Landing .box-2 h4,
    body.Landing .box-img-1 h3 {
        font-size: 30px;
    }

    body.Landing .price-value {
        font-size: 50px;
    }

    body.Landing .price-row {
        padding: 40px 20px 0;

    }

    body.Landing .button-bottom button {
        width: 147px;
    }

    body.Landing .content-section-2.content-section-bottom .box-content h3 {
        font-size: 20px;
    }
}

@media only screen and (min-width:768px) {
    svg.navbar-close {
        display: none;
    }
}

@media only screen and (max-width:767px) {
    body.Landing h1 {
        font-size: 28px;
    }

    .medium-heading,
    body.Landing .box-1 h3,
    body.Landing .content-section-2 h3 {
        font-size: 27px;
        width: 100%;
    }

    body.Landing .box-1,
    .box-2 {

        min-height: 284px;

        padding: 20px;

    }

    body.Landing .box-2 h3 {
        font-size: 30px;
        font-weight: bold;
    }

    body.Landing .box-2 h4,
    body.Landing .box-img-1 h3 {
        font-size: 27px;
        text-align: left;
        font-weight: bold;
    }

    body.Landing .content-with-image {
        padding: 20px;
    }

    body.Landing .price-row {
        padding: 40px 20px 0;

    }

    body.Landing .price-list-content-br {
        border-right: none;
    }

    body.Landing .button-bottom button {
        width: 47%;
    }

    body.Landing .content-section-2 {
        padding: 30px 0;
    }

    body.Landing .content-section-2.content-section-bottom .box-content {
        padding: 20px;
        height: auto;
    }

    body.Landing .box-content {
        padding: 20px;
        border-radius: 11px;

        height: auto;

    }

    body.Landing .content-head {
        text-align: left;
        margin-bottom: 40px;
        margin-top: 0;
    }

    body.Landing .pricing-section .content-head {
        text-align: left;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    body.Landing .box-img {
        margin-top: 20px;
    }

    body.Landing .menu {
        margin-top: 20px;
    }

    body.Landing .tab-section ol {
        padding: 0 15px;
    }

    body.Landing .main-banner {
        padding: 0px 0;
        text-align: center;
    }

    body.Landing .right-menu a {
        font-size: 16px;
        margin-left: 10px;
    }

    body.Landing .navbar-collapse {
        display: block !important;
        position: fixed;
        right: -100%;
        width: 300px;
        height: 100%;
        z-index: 9;
        background: #070707;
        top: 0;
        text-align: left;
        padding-top: 9px;
        transition: all 0.5s ease;
    }

    body.Landing .collapse.navbar-collapse.sidebar-open {
        right: 0;
    }

    body.Landing div#v-pills-tab {
        overflow: scroll;
    }

    body.Landing .nav-pills .nav-link {
        white-space: nowrap;
    }
}

body.Landing button.navbar-toggler:focus {
    border: none;
    box-shadow: none;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600 !important;
}

body.Landing .bg-overlay {
    position: relative;
    overflow: hidden;
}

body.Landing .bg-overlay * {
    z-index: 2;
}

body.Landing .bg-overlay:after {
    background: #000;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0.4;
}

.Toastify__toast-container{
    z-index: 999999 !important;
}

@media only screen and (max-width:1024px) {
    .medium-heading {
        font-size: 42px;
    }

    body.Landing .content-section-2 h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    body.Landing .box-content {
        padding: 30px;

    }
}

@media only screen and (max-width:991px) {
    .medium-heading {
        font-size: 32px;
    }

    body.Landing .box-1,
    .box-2,
    body.Landing .content-with-image {
        padding: 40px;
    }

    body.Landing .box-1 h3,
    body.Landing .box-2 h3,
    body.Landing .box-2 h4,
    body.Landing .box-2 h4,
    body.Landing .box-img-1 h3 {
        font-size: 30px;
    }

    body.Landing .price-value {
        font-size: 50px;
    }

    body.Landing .price-row {
        padding: 40px 20px 0;

    }

    body.Landing .button-bottom button {
        width: 147px;
    }

    body.Landing .content-section-2.content-section-bottom .box-content h3 {
        font-size: 20px;
    }
}

@media only screen and (min-width:768px) {
    body.Landing svg.navbar-close {
        display: none;
    }
}

@media only screen and (max-width:767px) {
    body.Landing h1 {
        font-size: 28px;
    }

    .medium-heading,
    body.Landing .box-1 h3,
    body.Landing .content-section-2 h3 {
        font-size: 27px;
        width: 100%;
    }

    body.Landing .box-1,
    body.Landing .box-2 {

        min-height: 284px;

        padding: 20px;

    }

    body.Landing .box-2 h3 {
        font-size: 30px;
        font-weight: bold;
    }

    body.Landing .box-2 h4,
    body.Landing .box-img-1 h3 {
        font-size: 27px;
        text-align: left;
        font-weight: bold;
    }

    body.Landing .content-with-image {
        padding: 20px;
    }

    body.Landing .price-row {
        padding: 40px 20px 0;

    }

    body.Landing .price-list-content-br {
        border-right: none;
    }

    body.Landing .button-bottom button {
        width: 47%;
    }

    body.Landing .content-section-2 {
        padding: 30px 0;
    }

    body.Landing .content-section-2.content-section-bottom .box-content {
        padding: 20px;
        height: auto;
    }

    body.Landing .box-content {
        padding: 20px;
        border-radius: 11px;

        height: auto;

    }

    body.Landing .content-head {
        text-align: left;
        margin-bottom: 40px;
        margin-top: 0;
    }

    body.Landing .pricing-section .content-head {
        text-align: left;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    body.Landing .box-img {
        margin-top: 20px;
    }

    body.Landing .menu {
        margin-top: 20px;
    }

    body.Landing .tab-section ol {
        padding: 0 15px;
    }

    body.Landing .main-banner {
        padding: 0px 0;
        text-align: center;
    }

    body.Landing .right-menu a {
        font-size: 16px;
        margin-left: 10px;
    }

    .navbar-collapse {
        display: block !important;
        position: fixed;
        right: -100%;
        width: 300px;
        height: 100%;
        z-index: 9;
        background: #070707;
        top: 0;
        text-align: left;
        padding-top: 9px;
        transition: all 0.5s ease;
    }

    .collapse.navbar-collapse.sidebar-open {
        right: 0;
    }

    body.Landing div#v-pills-tab {
        overflow: scroll;
    }

    body.Landing .nav-pills .nav-link {
        white-space: nowrap;
    }

    body.Landing a.btn-style {
        padding: 10px 15px;
        font-size: 14px;
    }

    body.Landing .right-menu a {
        font-size: 14px;
    }
}

body.Landing button.navbar-toggler:focus {
    border: none;
    box-shadow: none;
}

section.about-us-container-section .about-content h3 {
    margin-bottom: 10px;
}

section.about-us-container-section .about-content p {
    font-size: 18px;
}

section.about-us-container-section .about-content span.small-heading {
    margin-bottom: 15px;
}

section.about-us-container-section .about-content {
    margin: 40px 0;
}

.contact-content p {
    font-size: 18px;
}

.contact-form {
    max-width: 500px;
    margin-top: 18px;
    display: inline-block;
    width: 100%;
    font-size: 18px;
}

.contact-label label {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0px;
}

.contact-label input,
.contact-label textarea {
    background: #0000;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #fff;
    margin-top: 10px;
    padding: 8px;
    width: 100%;
}

.contact-form button {
    padding: 7px 20px;
    border-radius: 21px;
    border: none;
    text-decoration: none;
    background: #f2d131;
    background-image: linear-gradient(90deg, #7e4601 0%, #eecb2d 35%, #f2d131 100%);
    color: #000;
    margin-top: 15px;
    margin-bottom: 67px;
    font-size: 14px;
}

@media only screen and (max-width:767px) {
    .order-img-2 {
        order: 2 !important;
    }
}


.terms-content * {
    font-family: Poppins, sans-serif !important;
    font-size: 18px !important;
    font-weight: normal !important;
    color: #fff !important;
    border-color: #fff !important;
    background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
    font-size: 30px !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
    font-size: 16px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
    font-size: 30px !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
    font-size: 22px !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
    font-size: 18px !important;
}

[data-custom-class='link'],
[data-custom-class='link'] *,
.terms-content a {
    color: #f2d131 !important;
    font-size: 18px !important;
    word-break: break-word !important;
}

section.pricing-content ul{
    font-size: 18px;
}