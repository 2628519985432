body.home {
    background-color: #070707;
    /* font-family: Inter, sans-serif; */
    color: #fff;
    font-size: 16px;
    line-height: 1.333;
    font-weight: 400;
}

body.home header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999999;
}

body.home .container-xl {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 64em;
    margin-right: auto;
    margin-left: auto;
    padding: 1.25em;
}

body.home header .container-xl {
    z-index: 9999;
}

body.home .logo img {
    width: 150px;
}

body.home .announcment-bar {
    background: #070707;
    border: 1px solid #5c5c5c;
    padding: 7px;
    font-size: 13px;
    font-weight: bold;
    color: #fff;
}

body.home button.toggle-main {
    border: none;
    background: transparent;
}

/* body.home .button-small {
    border-style: solid;
    border-width: 1px;
    border-color: #5c5c5c;
    background-color: transparent;
    color: #fff;
    font-size: 0.75em;
    font-weight: 600;
    letter-spacing: 0px;
    padding: 0.50em 1.25em!important;
    border-radius:5px;
    text-decoration: none;
	    margin-right: 10px;
} */
body.home .button-small-bg {
    border: none;
    border-radius: 5px;
    background-color: #27272a;
    color: #fff;
    font-size: 0.75em;
    font-weight: 600;
    letter-spacing: 0px;
    padding: 0.50em 1.25em !important;
    text-decoration: none;
}

body.home .button-small-bg:hover {
    color: #fff
}

body.home .tab-html {
    width: calc(100% - 530px);
    margin: 0px auto;
    margin-bottom: 196px;
    padding: 15px;
    display: inline-block;
}

body.home .tab-html .nav-tabs {
    border: none;
    justify-content: space-around;
    background: #27272a;
    padding: 7px;
    border-radius: 7px;
}

body.home .tab-html .nav-tabs button {
    width: 50%;
    border-radius: 6px;
    padding: 6px;
    border: none;
    color: #fff;
}

body.home .tab-html .nav-tabs .nav-link.active {
    background: #070707;
    color: #fff;
}

body.home .items-inner {
    padding-right: 15px;
}

body.home .text-lg {
    font-size: 17px;
    color: #fff;
    text-align: left;
    margin-top: 20px;
}

body.home .chatbots-list {
    display: flex;
    overflow: hidden;
}

body.home .chatbots-box {
    margin: 0 20px;
}

body.home .chatbots-box img {
    width: 52px;
    max-width: 52px;
    margin-bottom: 7px;
    aspect-ratio: 1;
}

body.home .chatbots-box h3 {
    font-size: 16px;
}

body.home .chatbots-list-inner {
    overflow-x: scroll;
    display: flex;
}

body.home .chatbots-list ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

body.home .chatbots-list ::-webkit-scrollbar-track {
    background-color: #000;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

body.home .chatbots-list ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #6d6d6d;
}

body.home .items-box {
    display: flex;
    border: 1px solid #5c5c5c;
    justify-content: space-between;
    padding: 26px;
    border-radius: 10px;
    margin-bottom: 15px;
    text-align: left;
    font-size: 12px;
    align-items: center;
}

body.home .items-inner h3 {
    font-size: 16px;
}

body.home .items-inner p {
    margin-bottom: 3px;
    color: #ccc;
}

body.home button.next-btn {
    border: none;
    background: #fff;
    width: 29px;
    height: 29px;
    border-radius: 5px;
}

body.home .items-list {
    margin-top: 5px;
    display: inline-block;
    width: 100%;
}

body.home .items-box:hover {
    background: #27272a;
    cursor: pointer;
}

body.home .toggle-btn-form input[type=checkbox] {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
    opacity: 1;
}

body.home .toggle-btn-form label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

body.home .toggle-btn-form label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

body.home .toggle-btn-form input:checked+label {
    background: #bada55;
}

body.home .toggle-btn-form input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

body.home .toggle-btn-form label:active:after {
    width: 40px;
}

body.home .bottom-form {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
}

body.home .bottom-form form {
    /* max-width: 690px;
    margin: 0px auto;
    background: #000;
    border-top: 1px solid #5c5c5c;
    border-radius: 7px 7px 0px 0px; */
    margin: 0 auto;
    max-width: calc(100% - 525px);
    padding: 10px 15px 10px 0px;
}

body.home .toggle-btn-form {
    position: relative;
    display: flex;
    align-items: center;
}

body.home .top-form-part {
    display: flex;
    justify-content: space-between;
}

body.home .toggle-btn-form {
    position: relative;
    display: flex;
    align-items: center;
}

body.home .toggle-btn-form p {
    margin: 0;
    display: inline-block;
}

body.home button.insert-code {
    border: 1px solid #5c5c5c;
    background: transparent;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 6px 8px;
}

body.home button.insert-code svg {
    width: 20px;
    margin-right: 8px;
}

body.home .toggle-btn-form span {
    margin-left: 8px;
}

body.home .inner-form {
    background: radial-gradient(circle, rgba(7, 7, 7, 1) 0%, rgba(29, 29, 29, 1) 100%);
    align-items: center;
    border: 1px solid #5c5c5c;
    border-radius: 1rem;
    display: flex;
    margin: 0;
    position: relative;
}

body.home .file-type {
    position: relative;
    width: 50px;
}

body.home input#file-input {
    width: 20px;
    visibility: hidden;
    width: 0;
}

body.home textarea#chat-input-box {
    background: #0000;
    border: none;
    color: #fff;
    /* height: 52px; */
    max-height: 222px !important;
    padding: 15px 40px 15px 10px;
    resize: none;
    width: 100%;
    outline: none;
}

body.home #chat-input-box-hidden {
    padding: 15px 40px 15px 10px;
}

body.home textarea#chat-input-box::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

body.home textarea#chat-input-box::-webkit-scrollbar-track {
    background-color: #000;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

body.home textarea#chat-input-box::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #6d6d6d;
}

body.home .right-btn {
    width: 35px;
    display: flex;
    justify-content: space-evenly;
    margin-right: 10px;
}

body.home .right-btn button {
    border: 1px solid #5c5c5c;
    background: transparent;
    border-radius: 5px;
    width: 34px;
    padding: 6px;
    color: #fff;
    height: 32px;
    display: flex;
    align-items: center;
}

body.home .bottom-form form>p {
    color: #ddd;
    font-size: 13px;
}

body.home .bottom-form form>p a {
    color: #ddd;
    font-size: 13px;
}

body.home .chatbots-box.selected {
    border: 1px solid white;
    border-radius: 5px;
    padding: 3px;
}

body.home .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

body.home span.code-options {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
}

body.home span.code-options>* {
    cursor: pointer;
}

body.home span.code-options>.disabled {
    pointer-events: none !important;
}

.hljs-comment {
    white-space: break-spaces;
}

pre code {
    font-size: 16px;
}

#textarea textarea::-webkit-scrollbar {
    width: 4px;
}

#textarea textarea::-webkit-scrollbar-button {
    display: none;
}

#textarea textarea::-webkit-scrollbar-track {
    background-color: transparent;
}

#textarea textarea::-webkit-scrollbar-thumb {
    background: #d2d2d2;
}

body.home .prompt-container {
    gap: 0.75rem;
}

body.home .shimmerBG {
    background: #3a3a3a;
    background-image: linear-gradient(to right, #3a3a3a 0%, #3f3f3f 10%, #4a4a4a 20%, #3f3f3f 30%, #3a3a3a 50%, #3a3a3a 100%);
    background-repeat: no-repeat;
    background-size: 800px 200px;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: shimmer;
    -webkit-animation-timing-function: ease-in-out;
}

body.home .shimmerBG.title-label {
    width: 100%;
    height: 22px;
}

body.home .shimmerBG.title-line {
    width: 100%;
    height: 17px;
}

body.home .title-line-container {
    gap: 1rem;
    flex: 1 1 auto;
}

ul.histories {
    max-height: 600px;
    overflow-y: auto;
    position: relative;
    z-index: 9999;
}

ul.histories::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

ul.histories::-webkit-scrollbar-track {
    background-color: #000;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

ul.histories::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #6d6d6d;
}

ul.histories li:hover {
    background-color: #27272a;
}

code span {
    color: #fff;
}

.prompt-container>div.prompt {
    min-width: 0;
}

.code-syntax-container {
    overflow-x: auto;
}

.code-syntax-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.code-syntax-container::-webkit-scrollbar-track {
    background-color: #000;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.code-syntax-container::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #6d6d6d;
}

button.history svg {
    fill: #fff;
    height: 38px;
    width: 38px;
}

button.history {
    position: absolute;
    right: 0;
    background: transparent;
    border: none;
    display: none;
}

@keyframes shimmer {
    0% {
        background-position: -400px 0;
    }

    100% {
        background-position: 400px 0;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width:768px) {
    body.home .bottom-form {
        transition: all 0.5s ease;
        left: 0;
    }

    body.home .tab-html.tab-open,
    body.home .bottom-form.tab-open {
        left: 120px;
    }
}

@media only screen and (max-width:767px) {
    body.home .logo a {
        display: none;
    }

    body.home .sidebar-code {

        z-index: 9;
        background: #000;
    }

    .menu {
        margin-top: 20px;
    }

    a.button-small-bg {
        padding: 7px 8px;
    }
}

@media only screen and (max-width:1024px) {
    button.history {
        display: block;
    }

    .tab-html,
    .bottom-form form {
        width: calc(100% - 260px);
    }
    .sidebar-code-right {
        width: 250px;
        height: 100%;
        padding-left: 15px;
        position: relative;
        left: 0;
        transition: all 0.5s ease;
        display: inline-block;
        vertical-align: top;
        border-left: 1px solid #5c5c5c;
    }
    .sidebar-code-right.open-right {
        background: #000;
        left: inherit;
        padding-top: 20px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99999999;
    }

    .sidebar-overlay.open-right {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 9;
        backdrop-filter: blur(6px);
    }

    .sidebar-code-right * {
        position: relative;
        z-index: 999;
    }
    .sidebar-code-right{
        padding-right: 15px;
    }
    .sidebar-code-right svg.navbar-close {
        /* float: right; */
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:767px) {
    .sidebar-code {
        z-index: 9;
        background: #000;
        width: 100%;
        padding: 0 15px;
    }

    .tab-html, .bottom-form form {
        width: 100%;
    }

    .sidebar-code-right {
        margin-bottom: 70px;
        padding-right:15px;
    }
}


@media only screen and (max-width:991px) {
    body.home .tab-html {
        width: 100%;
        order: 2;
    }

    .sidebar-code {
        width: 100%;
        order: 1;
    }


    .sidebar-code-right {
        width: 100%;
        order: 1;
    }

    body.home header {
        width: 100%;
    }

    body.home .bottom-form form {
        width: 100%;
        max-width: calc(100% - 15px);
    }
}